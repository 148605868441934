@import '../variables';
@import '../utils/functions';
@import '../base/macros';
@import './selectionItem';

.checkout-teaser {
  display: flex;
  flex-direction: column;
  background-color: $white;

  &:not(:first-of-type) {
    margin-top: clamp-it(1rem, 2rem);
  }

  @include xmedium {
    flex-direction: row;
  }

  &--active {
    border: 1px solid $primary;

    &-single {
      border-bottom: 1px solid $gray-300;
    }
  }

  &__container {
    &:not(:first-of-type) {
      margin-top: clamp-it(0.8rem, 1.4rem);
    }
  }

  &__left {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include xmedium {
      flex: 1 1 28%;
      max-width: 28%;
    }
  }

  &__center {
    padding: clamp-it(1rem, 2.5rem);

    > h4 {
      line-height: 120%;
      margin-bottom: clamp-it(0.65rem, 1rem);
      font-weight: 500;

      @include medium {
        line-height: 130%;
      }
    }

    > p,
    > div {
      @extend %body-small;
    }

    p {
      margin-bottom: 0 !important;
    }

    .truncated-markup {
      p {
        margin-bottom: clamp-it(0.5rem, 0.8rem) !important;
      }

      > p {
        margin-bottom: 0 !important;
      }
    }

    @include xmedium {
      flex: 1 1 51%;
      max-width: 51%;
    }
  }

  &__read-more {
    text-decoration: underline;
    cursor: pointer;
    margin-top: clamp-it(0.75rem, 1rem);
  }

  &__right {
    padding: clamp-it(1rem, 2.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include xmedium {
      flex: 1 1 21%;
      max-width: 21%;
      border-left: 1px solid $gray-300;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    &__price {
      @include fluid-fonts(1.375rem, 1.75rem, 130%, 150%);

      @include xmedium {
        margin-bottom: clamp-it(0.5rem, 1rem);
      }
    }

    &--single-room {
      padding: 0;

      .selection-item {
        &__outer {
          display: block;
          margin: 0 !important;
          padding: 0;
          height: 100%;
          width: 100%;

          @include xmedium {
            flex: initial;
            max-width: initial;
          }

          @include xxlarge {
            flex: initial;
            max-width: initial;
          }
        }

        &__inner {
          justify-content: center;
          padding: clamp-it(0.95rem, 1.5rem) 0;
        }

        &__info {
          display: flex;
          flex-direction: column;
        }

        &__name {
          @extend %body;
        }
      }
    }
  }

  &__button.btn {
    padding-left: clamp-it(1.6rem, 3rem);
    padding-right: clamp-it(1.6rem, 3rem);

    &.checkout-teaser__button--selected {
      background-color: $secondary;
      border-color: $secondary;
      color: $primary;

      &:hover {
        color: $primary;
        background-color: transparent;
      }
    }
  }

  &__additional {
    margin-top: clamp-it(0.85rem, 1.5rem);
  }
}

.bus-teasers,
.opera-teasers,
.meal-selection,
.extras-selection,
.excursion-packages,
.single-excursions {
  .checkout-teaser__container {
    &:not(:first-child) {
      margin-top: clamp-it(1rem, 1.4rem);
    }
  }
}

.bus-teasers,
.opera-teasers {
  .checkout-teaser__right {
    display: none;

    @include xmedium {
      display: flex;
      border-left: 0;
    }
  }
}
