@import '../utils/functions';
@import '../base/macros';
@import '../variables';

.results__list__item {
  padding: clamp-it(0.35rem, 0.55rem);
  position: relative;
  background-color: $white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: none !important;
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 75%;
    aspect-ratio: 4 / 3;
    margin-bottom: clamp-it(0.7rem, 1.2rem);

    &__map {
      opacity: 0;
      z-index: -1;
      transition:
        opacity 0.4s ease-in,
        z-index 0.4s ease-in;

      &--active {
        opacity: 1;
        z-index: 1;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &__travel-types {
    @extend %body-small;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }

  &__favorite {
    position: absolute;
    left: clamp-it(0.8rem, 1.3rem);
    top: clamp-it(0.8rem, 1.3rem);
    cursor: pointer;
    z-index: 2;

    path {
      stroke: $secondary-accent-green !important;
      transition: fill 0.25s ease-in;
    }

    &:hover,
    &--active {
      path {
        fill: $secondary-accent-green;
      }
    }
  }

  &__rating {
    background-color: $secondary-accent-yellow;
    color: $primary;
    padding: clamp-it(0.25rem, 0.45rem) clamp-it(0.35rem, 0.55rem) clamp-it(0.35rem, 0.55rem);
    display: flex;
    align-items: center;
    position: absolute;
    right: clamp-it(0.35rem, 0.55rem);
    top: clamp-it(0.75rem, 1rem);
    z-index: 2;

    > svg {
      @include fluid-fonts(0.95rem, 1.05rem, 100%, 100%);
      margin-right: clamp-it(0.25rem, 0.35rem);
      height: 1em;
      width: 1em;
    }

    > span {
      @include fluid-fonts(0.75rem, 0.8125rem, 100%, 100%);
      padding-top: 0.25rem;
    }
  }

  &__splash {
    padding: clamp-it(0.25rem, 0.45rem) clamp-it(0.35rem, 0.55rem) clamp-it(0.35rem, 0.55rem);
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;

    > span {
      @include fluid-fonts(0.75rem, 0.8125rem, 100%, 100%);
      padding-top: 0.25rem;
    }
  }

  &__content {
    padding: 0 clamp-it(0.65rem, 1.25rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    @include xlarge {
      padding: 0 0.1rem;
    }
  }

  &__title {
    @extend %body-large;
    margin-bottom: clamp-it(2rem, 3.5rem);

    @include large {
      line-height: 155% !important;
    }
  }

  &__delimiter {
    color: $gray-400;
    margin: 0 0 clamp-it(0.32rem, 0.55rem);
  }

  &__cache {
    @extend %body-small;
    margin-bottom: clamp-it(0.45rem, 0.85rem);

    &__element {
      display: flex;
      align-items: center;
      margin-right: clamp-it(0.4rem, 0.7rem);

      + div {
        margin-right: clamp-it(0.4rem, 0.7rem);
      }

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: clamp-it(0.2rem, 0.3rem);
      }

      > div {
        > span {
          display: inline-block;
          margin-right: clamp-it(0.35rem, 0.45rem);
        }

        > a {
          @include fluid-fonts(0.625rem, 0.8125rem, 100%, 100%);
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &__more-than-one {
        &:hover {
          text-decoration: underline;
        }
      }

      &--sold-out {
        color: $orange;
      }

      &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}
