@import '../utils/functions';
@import '../base/grid';
@import '../base/macros';

.product-teasers {
  position: relative;
  padding: $section-spacing-y 0 $section-spacing-y $section-spacing-x;

  @media (min-width: 1590px) {
    padding: $section-spacing-y $section-spacing-x;
  }

  &.pull-up-sibling {
    margin-bottom: calc(($section-spacing-y + 11rem) * -1) !important;
    padding-bottom: calc(($section-spacing-y + 11rem)) !important;

    @include medium {
      margin-bottom: calc(($section-spacing-y + 13rem) * -1) !important;
      padding-bottom: calc(($section-spacing-y + 13rem)) !important;
    }
  }

  &__wrapper {
    position: relative;
    min-height: 22rem;
  }

  &__container {
    @extend .max-width;
  }

  &__headline {
    @include fluid-fonts(1.5rem, 3rem, 150%, 140%);
    font-weight: 500;
    margin-bottom: clamp-it(0.88rem, 1.1rem);

    @include medium {
      margin-bottom: 0;
    }

    &__container {
      margin-bottom: clamp-it(3.3rem, 3.75rem);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      padding-right: $section-spacing-x;

      @include medium {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }

      a.alb-link {
        > span {
          border-bottom: 0;
        }

        &:hover {
          box-shadow: none;

          > span {
            border-bottom: 0;
          }
        }
      }
    }
  }

  &__outer {
    @extend %no-scrollbar;
    position: relative;
    width: 100%;
    overflow: auto;
    display: flex;
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;

    > div:not(:first-child) {
      margin-left: 1.25rem;

      @include medium {
        margin-left: 1.75rem;
      }
    }
  }
}

.product-teaser {
  position: relative;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:hover {
    text-decoration: none;
    box-shadow: none !important;
  }

  &__img-container {
    position: relative;
    padding-top: 78.5%;

    img {
      max-width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__splash {
    @include fluid-fonts(0.6875rem, 0.75rem, 110%, 133%);
    padding: clamp-it(0.25rem, 0.45rem) clamp-it(0.35rem, 0.55rem) clamp-it(0.35rem, 0.55rem);
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  &__badge {
    @include fluid-fonts(0.6875rem, 0.75rem, 110%, 133%);
    background-color: $secondary-accent-yellow;
    padding: clamp-it(0.4rem, 0.44rem) clamp-it(0.94rem, 1.94rem);
    position: absolute;
    left: 0;
    bottom: 0;

    @include medium {
      font-weight: 700;
    }
  }

  &__pacific {
    display: flex;
    position: relative;
    padding-bottom: clamp-it(1.5rem, 2rem);
    margin-top: clamp-it(1.5rem, 2.12rem);
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: currentColor;
      bottom: clamp-it(0.75rem, 1rem);
    }

    @include large {
      flex-direction: row;
      align-items: center;
    }

    &__infos {
      @include fluid-fonts(0.6875rem, 0.875rem, 110%, 110%);
      display: flex;
      font-weight: 700;
      order: 2;

      @include medium {
        font-weight: 500;
      }

      @include large {
        order: initial;
      }
    }
  }

  &__rating {
    margin-bottom: clamp-it(0.55rem, 0.75rem);

    @include large {
      margin-bottom: 0;
    }

    .rating-star {
      height: 0.75rem;
      width: 0.75rem;

      &:not(:last-child) {
        margin-right: 0.18rem;
      }
    }
  }

  &__text {
    @include fluid-fonts(1.25rem, 1.375rem, 160%, 170%);
  }

  &__tagline {
    @include fluid-fonts(0.875rem, 1rem, 180%, 190%);
  }

  &__divider {
    margin: 0 clamp-it(0.3rem, 0.42rem);
  }

  &__button {
    margin-top: clamp-it(1.5rem, 2rem);
  }
}
