@import '../variables';
@import '../utils/mediaQueries';
@import '../base/macros';

$content-nav-height: 48px;

.content-navigation {
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: 0 9px 10px -8px rgba(0, 0, 0, 0.5);

  @include medium {
    display: flex;
  }

  .scroll-container {
    @extend %no-scrollbar;

    display: flex;
    font-family: var(--albatros-font);
    color: $white;
    background: $primary-dark-green;
    overflow: auto;
    flex: 1;
    cursor: grab;
    user-select: initial;

    .center-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: $content-nav-height;
      white-space: nowrap;

      > div {
        display: flex;
        flex: 1;
        justify-content: center;

        > a {
          margin: 0 20px;
        }
      }
    }

    a {
      text-decoration: none;
      color: inherit;
      display: inline-flex;
      transition: box-shadow 0.25s ease-in-out;

      &.content-navigation__active,
      &:hover {
        box-shadow: inset 0 -2px 0 $white;
        font-weight: 500;
      }
    }

    &--grab {
      cursor: grabbing;
      user-select: none;

      a {
        pointer-events: none;
      }
    }
  }

  .cta-item {
    background: $secondary-accent-green;
    color: $primary-dark-green;
    height: $content-nav-height;
    white-space: nowrap;
    padding: 9px 10px 10px;
    transition: all 0.3s ease;
    display: block;
    text-align: center;

    svg {
      height: 12px;
      position: relative;
      top: -1px;
      margin-left: 0.3rem;
    }

    @include medium {
      padding: 9px 30px 10px;
      display: inline-block;

      &.hide {
        max-height: initial;
        opacity: 1;
        padding: 9px 30px 10px;
        width: initial;
      }

      &.show {
        max-height: initial;
        opacity: 1;
      }
    }
  }
}
