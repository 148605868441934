@import './utils/mediaQueries';
@import './variables';
@import './Bootstrap';
@import './base/base';
@import './components/components';

body {
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: scroll;
  background-position-x: 500px;
  background-position-y: 500px;
  background-color: $white;
  color: $primary;
}

hr {
  margin: clamp-it(0.875rem, 2.5rem) 0;
}

ul {
  margin-bottom: 0;
  padding-left: 1.3rem;
}

svg {
  overflow: visible;
}

.no-scroll {
  overflow: hidden !important;
}

.no-z-index {
  z-index: 0 !important;
}

.page {
  &__top {
    position: relative;
    z-index: 42;
  }

  &__center {
    &__wrapper {
      position: relative;
      min-height: 30rem;

      &--website-search {
        background-color: $primary-light-blue;
      }
    }
  }
}

.suspense-fallback {
  position: absolute;
  display: flex;
  height: 100dvh;
  width: 100dvw;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(229, 236, 239, 1);

  &--fit {
    height: 100%;
    width: 100%;
    background-color: rgba(229, 236, 239, 0.6);
  }

  &--fixed {
    position: fixed;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > *:first-child {
      margin-bottom: 4rem;
      z-index: 10000;
    }
  }

  &__spinner {
    .custom-spinner::after {
      background: lighten($gray-300, 2%);
    }

    &--only {
      margin-bottom: 0 !important;
    }
  }
}
